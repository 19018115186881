<template>
  <div class="modal-container">
    <div class="modal" v-click-outside="closeModal">
      <img src="@/assets/icons/cross.svg" alt="cross" class="close" @click="closeModal">
      <Form @submit="editGame">
        <div class="form-wrapper">
          <!-- Title -->
          <Input
            id="title"
            type="text"
            placeholder="Title"
            label="Title"
            class="bottom-spacing span-width"
            rules="required|max:30"
            maxLength="50"
            @change="updateData($event, 'title')"
            :value="item.title"
          />

          <!-- Url -->
          <Input
            id="url"
            type="text"
            placeholder="Link URL"
            label="Link URL"
            class="bottom-spacing span-width"
            rules="required"
            @change="updateData($event, 'url')"
            :value="item.url"
          />

          <!-- Icon -->
          <p class="title">Icon</p>
          <div class="upload-row">
            <input
              type="file"
              accept="image/*"
              id="icon"
              @change="uploadAttachments($event, 'icon')"
              hidden
            />
            <label for="icon" class="uploadBtn">Upload</label>
            <p>{{ iconFileName }}</p>
          </div>

          <!-- Image -->
          <p class="title">Image</p>
          <div class="upload-row">
            <input
              type="file"
              accept="image/*"
              id="image"
              @change="uploadAttachments($event, 'image')"
              hidden
            />
            <label for="image" class="uploadBtn">Upload</label>
            <p>{{ imageFileName }}</p>
          </div>

          <SubmitButton class="center">Edit</SubmitButton>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Form from "@/components/Form";
import Input from "@/components/Input";
import SubmitButton from "@/components/SubmitButton";
import Message from "@/components/Message";

import { attachmentStore } from '@/services/adminAPI/attachment'
import { staticLinkPut } from '@/services/adminAPI/staticLink'     
import { eventBus } from "@/main.js";

export default {
  props:['item'],
  components:{
    Form,
    Input,
    SubmitButton,
    Message
  },
  data(){
    return {
      iconFileName: '',
      imageFileName: '',
      linkDetails: {
        title: '',
        url: '',
        icon_url: '',
        img_url: ''
      }
    }
  },
  methods:{
    closeModal(){
      this.$emit('closeModal',true)
    },
    updateData(data,attr){
      this.linkDetails[attr] = data;
    },  
    async uploadAttachments(event,type) {
      const file = event.target.files;
      const filename = file[0].name;
      const fileType = file[0].type;

      //Check file validity
      if (filename.lastIndexOf(".") <= 0 || !fileType.includes("image")) {
        return this.$swal({
          title: "Invalid Attachment",
          text: "File Type Not Allowed",
          icon: "error",
        });
      } else if (filename.split(".").length > 2) {
        return this.$swal({
          title: "Invalid Attachment",
          text: "File with multiple extensions are not allowed",
          icon: "error",
        });
      }

      const data = new FormData();
      data.append("attachment", file[0]);
      data.append("type",'static_links');

      //Upload Attachment
      try {
        const response = await attachmentStore(data);

        if(type == 'icon'){
          this.linkDetails.icon_url = response.data.response.filename;
          this.iconFileName = filename;
        }else if(type == 'image'){
          this.linkDetails.img_url = response.data.response.filename;
          this.imageFileName = filename;
        }

      } catch (error) {
        return this.$swal({
          title: "Oops",
          icon: "error",
          text: error.response.data.userMessage,
        });
      }
    },
    async editGame(){
      eventBus.$emit("loader", true);


      try {
        const staticLinkPutAPI = await staticLinkPut(this.item.key,this.linkDetails);
        
        if(!staticLinkPutAPI.data.error){
          await this.$store.dispatch('callStaticLinks')
        }
      } catch (error) {
        console.log(error);
      }

      eventBus.$emit("loader", false);
      this.$emit('closeModal',true)

    },
  },
  created(){
    const filteredItem = [this.item].map(({title, url}) => {
      return {
        title,
        url
      }
    })[0]

    this.linkDetails = filteredItem
  }
}
</script>

<style lang="scss">
.modal-container {
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: grid;
  place-items: center;
  overflow: auto;

  .modal {
    background: #f0f0f0;
    box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.3);
    border-radius: 20px;
    padding: 30px;
    margin: 30px;
    min-width: 400px;
    position: relative;

    .close{
      max-width: 15px;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .img-wrapper {
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    .upload-row {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: 3px;
    }

    .uploadBtn {
      padding: 10px 16px;
      background-color: var(--gray414241);
      color: white;
      display: table;
      margin-right: 1rem;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
</style>
