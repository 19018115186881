<template>
  <mounting-portal mountTo="body" append>
    <Portal to="editStaticLink">
      <EditStaticLinks v-if="showEdit" :item="editItem" @closeModal="closeModalHandler"/>
    </Portal>
    <PortalTarget name="editStaticLink" />
    <aside :class="{visible : openSideBar}" @click="toggle">
      <div class="sidebar">
        <div class="nav-section"> 
          <ul>
            <NavItem icon="dashboard" name="Game Dashboard" to="/admin/dashboard" />
            <NavItem icon="leaderboard" name="Leaderboard" to="/admin/leaderboard" />
            <NavItem icon="contract" name="Contract Specification" to="/admin/contract" />
            <NavItem icon="marketdata" name="Market Data" to="/admin/market-data" />
            <NavItem icon="users" name="Users" to="/admin/users" />
            <NavItem icon="invite" name="Invite Friends" to="/admin/invite" />
            <NavItem icon="walkthrough" name="Game Walkthrough" to="/admin/game-walkthrough" />
            <NavItem icon="rules" name="Rules of Game" to="/admin/rules" />
            <NavItem icon="feedback" name="Feedback" to="/admin/feedback" />
            <NavItem icon="user" name="Profile" :to="profileRoute" v-if="isMobile" />
            <NavItem icon="signout" name="Sign Out" to="/admin/signout" />
          </ul>
          <div class="extenal-links-section">
            <template v-if="userRole ==='admin'">
              <div 
                @contextmenu.prevent.stop="handleClick($event, item)" 
                :key="item.id"
                v-for="item in getStaticLinks"
                >
                <ExternalLink icon="account"  
                  :link="item.url"
                  :iconImage="item.icon_url"
                  :img="item.img_url == BASE_IMAGE_URL ? null : item.img_url"
                >
                  {{item.title}}
                </ExternalLink>
              </div>
            </template>

            <div v-else>
              <ExternalLink icon="account"  
                :key="item.id"
                v-for="item in getStaticLinks"
                :link="item.url"
                :iconImage="item.icon_url"
                :img="item.img_url == BASE_IMAGE_URL ? null : item.img_url"
              >
                {{item.title}}
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>

      <vue-simple-context-menu
        :elementId="'myFirstMenu'"
        :options="rightClickMenuOptions"
        :ref="'vueSimpleContextMenu1'"
        @option-clicked="optionClicked"
      >
      </vue-simple-context-menu>
    </aside>
  </mounting-portal>
</template>

<script>
import NavItem from '../NavItem';
import {MountingPortal} from 'portal-vue'
import {eventBus} from '../../main';
import ExternalLink from '../ExternalLink';

import { staticLink } from '@/services/api/staticLink.js'

import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";

import { Portal, PortalTarget } from "portal-vue";

import EditStaticLinks from '@/components/Admin/EditStaticLinks'

export default {
  components:{
    NavItem,
    MountingPortal,
    ExternalLink,
    VueSimpleContextMenu,
    Portal,
    PortalTarget,
    EditStaticLinks
  },
  data(){
    return{
      openSideBar : true,
      profileRoute: '',
      isMobile: false,
      BASE_IMAGE_URL :"https://kfsimulation-stagingassets.s3-ap-southeast-1.amazonaws.com/",
      rightClickMenuOptions: [
        {
          name: 'Edit',
          slug: 'edit'
        },
      ],
      showEdit: false,
      editItem: ''
    }
  },
  async created(){
    eventBus.$on('toggleSideBar', () => {
      this.openSideBar = true;
    });
  },
  mounted(){
    var windowWidth = window.innerWidth;
    windowWidth > 768 ? this.openSideBar =  true : this.openSideBar = false;
    windowWidth > 768 ? this.isMobile =  false : this.isMobile = true;


    const vm = this;

    window.addEventListener("resize", function () {
      var windowWidth = window.innerWidth;
      windowWidth > 768 ?  vm.openSideBar = true : vm.openSideBar = false;
      windowWidth > 768 ?  vm.isMobile = false : vm.isMobile = true;
    });
    this.profileRoute = this.userRole === 'admin' ? '/admin/profile/' : '/profile'; 
  },
  methods:{
    toggle(){
      const windowInnerWidth = window.innerWidth;
      if(windowInnerWidth < 768){
        this.openSideBar = !this.openSideBar;
      }
    },
    handleClick (event, item) {
      this.$refs.vueSimpleContextMenu1.showMenu(event, item)
    },
    optionClicked(event){
      this.editItem = event.item;
      this.showEdit = true;
    },
    closeModalHandler(){
      this.showEdit = false;
    }
  },
  computed:{
    getStaticLinks(){
      return this.$store.getters.getStaticLinks;
    }
  }
}
</script>

<style lang="scss" scoped>
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarGray686868) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarGray686868);
  border-radius: 20px;
}


aside{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 230px;
  transform: translateX(-100%);
  transition: all 0.3s;

   @media screen and (max-width:768px){
    width: 100%;
  }

  &.visible {
    transform: translateX(0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  .sidebar{
    position: absolute;
    top: 63px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    max-width: 200px;

    @media screen and (max-width:768px){
      top: 0;
      width: 200px;
    }
  }

  .nav-section{
    background: var(--gray414241);
    height: calc(100vh - 60px);
    overflow: auto;

    @media screen and (max-width:768px){
      height: 100vh;
    }

    .extenal-links-section{
      img{
        margin-left: 10px;
        height: auto;
        width: 180px;
      }
    }
  }

}
</style>    